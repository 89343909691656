@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(//db.onlinewebfonts.com/c/5162e411847d5109a5b62287d39458f8?family=PantonMtav3);

html,body{
    width: 100%;
    height: 100%;
}

@layer components{
    .panton{
        font-family: 'PantonMtav3';
        font-weight: 400;
    }
}

.splide__pagination{
    display: flex;
    transform: translate(104px, -30px);
}

.splide__pagination li button{
    width: 15px;
    height: 15px;
    background-color: transparent;
    border: 2px solid white;
}

.splide__pagination__page{
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: transparent;
    border: 2px solid white;
}

@media (min-width: 1024px){
    .splide__pagination{
        transform: translate(250px, -30px);
    }
}

@media (min-width: 1536px){
    .splide__pagination{
        transform: translate(450px, -30px);
    }
}